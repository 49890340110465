import React from "react";
import { graphql } from "gatsby";

import Img from "gatsby-image";
import SEO from "../components/seo";
import styled from 'styled-components';
import { LinkButton, Tag } from "../components/buttons";
import { Box, Flex, Grid, List, Spacer, Wrapper} from "../components/containers";
import * as Text from "../components/type";
import { Download } from "react-feather";
import { Link } from "gatsby";
import { getPath } from "../../helpers";

const ListItem = styled.li`
    --delay: ${props => props.delay}ms;
    @keyframes stagger {
        from {
            opacity: 0;
            transform: translate(200px, 0px);
        }
    
        to {
            opacity: 1;
            transform: translate(0px, 0px);
        }    
    }

    animation: 750ms stagger var(--delay) ease-out;
    animation-fill-mode: both;
`;

const Layout = styled(Grid)`
    align-items: items-stretch;
`;

const ImageContainer = styled(Box)`
    aspect-ratio: 4 / 3;
`
export default ({data}) =>
<Wrapper>
    <SEO title="About"/> 
    <Flex id="content" className="enter-left" alignItems={["center"]} width="100%">
        <Layout display="flex" alignItems="center" flexDirection={["column", "column", "row", "row"]} p={[4]}>
            <ImageContainer flexBasis={["auto", "50%"]} p={[0,0,4]} width={["100%"]}>
                <Img fluid={data.aboutMe.profileImage.fluid}/>
            </ImageContainer>
            <Box flexBasis={["auto","50%"]} p={[0,0,4]}>
                <Box my={4}>
                    <Text.RichText json={data.aboutMe.bio.json} />
                    <Spacer my={[2, 2, 2, 3]}/>
                </Box>
                <Box my={4}>
                    <Text.Heading fontFamily="display" fontSize={[2,3,4]}>Skills</Text.Heading>
                    <Spacer mb={4}/>
                    <List display="flex" flexWrap="wrap" gap={1} css={{"gap": "1rem"}}>
                    {data.tags.edges.map((edge, idx) =>
                        <Tag key={idx} fontSize={['.75rem']}>
                            <Link to={`/${getPath(edge.node.title)}`}>{edge.node.title}</Link> 
                        </Tag>
                    )}
                    </List>
                    <Spacer mt={4}/>
                    {data.aboutMe.resume &&
                    <LinkButton text={"Resume"} link={`https:${data.aboutMe.resume.file.url}`}>
                        <Box display="inline" ml={3}>
                            <Download />
                        </Box>
                    </LinkButton>
                    }
                </Box>
                <Box my={4}>
                    <Text.Heading fontFamily="display" fontSize={[2,3,4]}>Contact</Text.Heading>
                    <Spacer mb={4}/>
                    {data.contacts &&
                    <List display={["initial", "flex", "flex"]} flexWrap="wrap">
                    {data.contacts.edges.map(contact => {
                        let name = contact.node.name;
                        let contactElement;
                        if (contact.node.link) {
                            contactElement = <LinkButton text={name} link={contact.node.link} />;
                        }
                        return <li key={name}>{contactElement}</li>;
                    })}
                    </List>
                    }
                </Box>
            </Box>
        </Layout>
    </Flex>
</Wrapper>;

export const query = graphql`
query {
    contacts: allContentfulContact {
        edges {
            node {
                name
                link
            }
        }
    }
    aboutMe: contentfulAbout {
        name
        profileImage {
            fluid(quality: 100) {
                ...GatsbyContentfulFluid
            }
        }
        resume {
            file {
                url
            }
        }
        bio {
            json
        }
    }
    tags: allContentfulSubcategory {
        edges {
            node {
                title
        }
    }
}
}
`;